



























































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Prop } from "vue-property-decorator";
import moment from "moment";
import { NftModel } from "@/models/nft-model";

@Observer
@Component({
  components: {
    CharacterIndex: () => import("@/components/character/character-index.vue"),
    CharacterAvatar: () => import("@/components/character/character-avatar.vue"),
    CharacterStat: () => import("@/components/character/character-stat.vue"),
  },
})
export default class Summon extends Vue {
  @Prop() character!: NftModel;
}
